<template>
  <v-container
    fill-height
    fluid
    class="pa-0"
  >
    <v-card
      width="100%"
      height="100%"
    >
      <v-toolbar
        flat
        width="100%"
        class="toolbar"
      >
        <v-btn
          icon
          rounded
          @click.stop="$router.back()"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-toolbar-title class="font-weight-light ml-n4">
          {{ $t('actions|add_new_note') }}
        </v-toolbar-title>
      </v-toolbar>
      <v-textarea
        v-model="note"
        :rules="rules"
        :height="$vuetify.breakpoint.height - 210 + 'px'"
        counter
        outlined
        :label="$t('actions|type_text_here')"
        class="px-8 mt-4"
      />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          rounded
          dense
          small
          :disabled="disabled"
          min-width="150"
          :loading="loading"
          class="mb-2"
          @click="submit"
        >
          {{ $t('actions|submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      loading: false,
      note: '',
      rules: [v => v.length <= 350 || 'Max 350 characters'],
      disabled: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    })
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    },
    note (value) {
      if (value.length > 350) {
        this.disabled = true
      } else {
        this.disabled = false
      }
    }
  },
  methods: {
    ...mapActions({
      createMyNote: 'account/createMyNote'
    }),
    reset () {
      this.note = ''
    },
    async submit () {
      this.loading = true
      const payload = {
        myNotes: [
          { value: this.note }
        ]
      }
      try {
        await this.createMyNote(payload)
        this.reset()
        this.$router.back()
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
